import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowSelectPaymentOption } from '../payflow-client';
import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { BrandCard } from '../components/BrandCard';

import { TestDummyCard } from '../payment-integrations/TestDummyCard';
import { NetsEasy } from '../payment-integrations/NetsEasy';
import { SwedbankCheckout } from '../payment-integrations/SwedbankCheckout';
import { WorldlineBambora } from '../payment-integrations/WorldlineBambora';
import { Neonomics } from '../payment-integrations/Neonomics';
import { EFaktura } from '../payment-integrations/EFaktura';
import { Riverty } from '../payment-integrations/Riverty';

export const PaymentPage: FC = () => {
  const { t } = useTranslation();
  const { PaymentService } = useContext(PayflowContext);
  return (
    <Page
      id="payment"
      title={t('Payment')}
      queryFn={PaymentService ? PaymentService.selectPaymentOption : null}
    >
      {page => {
        if (page?.data && 'payflow' in page.data) {
          const payflow = page?.data?.payflow;
          const payment_option = page?.data?.payment_option as PayflowSelectPaymentOption

          switch (payment_option?.integration_identifier) {
            case undefined:
              console.log('No integration_identifier found in payment_option');
              break;
            case 'testdummy_card':
              return <TestDummyCard payflow={payflow} />;
            case 'nets_easy':
              return (
                <NetsEasy payflow={payflow} payment_option={payment_option} />
              );
            case 'swedbank_checkout':
              return (
                <SwedbankCheckout
                  payflow={payflow}
                  payment_option={payment_option}
                />
              );
            case 'worldline_bambora':
              return (
                <WorldlineBambora
                  payflow={payflow}
                  payment_option={payment_option}
                />
              );
            case 'neonomics':
              return <Neonomics payment_option={payment_option} />;
            case 'riverty':
              return <Riverty payflow={payflow} payment_option={payment_option} />;
            case 'eFaktura':
              return <EFaktura payflow={payflow} payment_option={payment_option} />;
            default: {
              console.error(
                `Payflow app is not set up to handle payments with integration_identifier '${payment_option?.integration_identifier}'.`,
              );
              return (
                <BrandCard heading={t('Unsupported payment option')}>
                  <p className="text-base font-normal pb-3">
                    {t(
                      `The merchant does not yet support the payment option you selected.`,
                    )}
                  </p>
                  <p>
                    {t(`Please contact the merchant for more information.`)}
                  </p>
                </BrandCard>
              );
            }
          }
        }
        return null;
      }}
    </Page>
  );
};
