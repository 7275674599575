import React, { FC, FormEvent } from 'react';
import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';

import {
  ApiError,
  PayflowGetResponse,
  PayflowSelectPaymentOption,
  RivertyOutcome,
  RivertyService,
} from '../payflow-client';
import { BrandCard } from '../components/BrandCard';
import { currencyFormat } from '../utils/locale-utils';
import Spinner from '../components/Spinner';
import apiErrorToText from '../utils/api-errors-parser';
import { AlertDialog } from '../components/AlertDialog';
import { TextField } from '../components/inputs/TextField';

import riverty_logo from '../assets/riverty-checkout-logo.svg';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type RivertyProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const Riverty: FC<RivertyProps> = ({ payflow, payment_option }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const [error, setError] = React.useState<string | null>(null);

  const [paymentMethods, setPaymentMethods] = React.useState<any[] | null>(
    null,
  );
  const [selectedMethod, setSelectedMethod] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState<string>('');
  const [ssnNumber, setSsnNumber] = React.useState<string>('');

  const [street, setStreetName] = React.useState<string>('');
  const [street_number, setStreetNumber] = React.useState<string>('');
  const [postal_code, setPostalCode] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');

  const spanClasses = 'inline-block mb-2 text-base font-medium';

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);

    if (paymentMethods === null) {
      RivertyService.getAvailableMethods(payflow?.id as string, {
        email,
        ssn_number: ssnNumber,
        ...(!payment_option?.script_additional_data?.valid_address ? {
          address: {
            street,
            street_number,
            postal_code,
            city,
          },
        } : {})
      })
        .then(response => {
          setPaymentMethods(response.paymentMethods);
        })
        .catch(error => {
          setError(apiErrorToText(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      RivertyService.initiate(payflow?.id as string, {
        email,
        ssn_number: ssnNumber,
        payment_method: selectedMethod,
      })
        .then(response => {
          document.location.href = response.redirect_url;
        })
        .catch(error => {
          if (error instanceof ApiError) {
            setError(apiErrorToText(error));
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <BrandCard>
      <AlertDialog
        open={Boolean(error)}
        onClose={() => setError(null)}
        heading={t('Error')}
        content={error}
        variant="error"
      />
      <div className="flex items-center justify-center">
        <img src={riverty_logo} alt='Riverty' />
      </div>
      <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
        {!paymentMethods && (
          <>
            <label htmlFor="email">
              <span className={spanClasses}>{t('Email')}</span>
              <TextField
                type="email"
                id="email"
                name="email"
                placeholder={t('Email')}
                autoComplete="email"
                required
                onChange={e => setEmail(e.target.value)}
              />
            </label>
            <label htmlFor="ssn_number">
              <span className={spanClasses}>{t('SSN number')}</span>
              <TextField
                type="text"
                id="ssn_number"
                name="ssn_number"
                placeholder={t('SSN number')}
                required
                onChange={e => setSsnNumber(e.target.value)}
              />
            </label>
          </>
        )}
        {!payment_option?.script_additional_data?.valid_address ? (
          <>
            <label>{t('Address')}</label>
            <div className="flex space-x-4">
              <TextField
                className="flex-auto"
                type="text"
                id="streetName"
                name="streetName"
                placeholder="Street name"
                required
                onChange={e => setStreetName(e.target.value)}
              />
              <TextField
                className="flex-none"
                widthClass="w-32"
                type="text"
                id="streetNumber"
                name="streetNumber"
                placeholder="Number"
                onChange={e => setStreetNumber(e.target.value)}
              />
            </div>
            <div className="flex space-x-4">
              <TextField
                className="flex-none"
                widthClass="w-32"
                type="text"
                id="postalCode"
                name="postalCode"
                placeholder="Postal code"
                required
                onChange={e => setPostalCode(e.target.value)}
              />
              <TextField
                className="flex-auto"
                type="text"
                id="city"
                name="city"
                placeholder="City"
                required
                onChange={e => setCity(e.target.value)}
              />
            </div>
          </>
        ) : null}
        {paymentMethods && paymentMethods.length > 0 && (
          <label htmlFor="paymentMethod">
            <span className={spanClasses}>{t('Plan')}</span>
            <div className="space-y-4">
              {paymentMethods?.map((method: any, index: number) => (
                <label
                  className={classNames(
                    index == selectedMethod
                      ? 'border-indigo-600 ring-2 ring-indigo-600'
                      : 'border-gray-300',
                    'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                  )}
                >
                  <input
                    type="radio"
                    name="payment-method"
                    value={index}
                    className="sr-only"
                    onChange={() => {
                      setSelectedMethod(index);
                    }}
                  />
                  <span className="flex items-center">
                    <span className="flex flex-col text-sm">
                      <span
                        id="server-size-0-label"
                        className="font-medium text-gray-900"
                      >
                        {method.title}
                      </span>
                      <span
                        id="server-size-0-description-0"
                        className="text-gray-500"
                      >
                        <span className="block sm:inline">{method.tag}</span>
                        {method.type == 'Installment' && (
                          <>
                            <span
                              className="hidden sm:mx-1 sm:inline"
                              aria-hidden="true"
                            >
                              &middot;
                            </span>{' '}
                            <span className="block sm:inline">
                              {method.installment.numberOfInstallments}{' '}
                              {t('months')} /{' '}
                              {currencyFormat(
                                method.installment.installmentAmount * 100,
                              )}{' '}
                              (
                              {currencyFormat(
                                method.installment.totalAmount * 100,
                              )}{' '}
                              {t('total amount')})
                            </span>
                          </>
                        )}
                      </span>
                    </span>
                  </span>
                  <span
                    className="pointer-events-none absolute -inset-px rounded-lg border-2"
                    aria-hidden="true"
                  ></span>
                </label>
              ))}
            </div>
          </label>
        )}
        {paymentMethods && paymentMethods.length == 0 && (
          <div className="flex items-center justify-center">
            <p>{t('No payment methods available from Riverty.')}</p>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
        <button
          type="submit"
          className="w-full h-14 font-medium text-lg btn-primary"
          disabled={
            isLoading || Boolean(paymentMethods && paymentMethods.length == 0)
          }
        >
          {t('Next')}
        </button>
      </form>
    </BrandCard>
  );
};

interface RivertyPendingProps {
  payflow: PayflowGetResponse | null | undefined;
}

export const RivertyPending: FC<RivertyPendingProps> = ({ payflow }) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string | null>(null);
  const [onErrorClose, setOnErrorClose] = React.useState<(() => void) | null>(
    null,
  );
  const [errorRedirectURL, setErrorRedirectURL] = React.useState<string | null>(null);

  const [, setLocation] = useLocation();

  const poll = async () => {
    const response = await RivertyService.getOutcome(payflow?.id as string);
    try {
      if (response.outcome == RivertyOutcome.ACCEPTED) {
        // redirect to receipt page.
        setLocation(`/${payflow?.id}/receipt`);
      } else if (response.outcome == RivertyOutcome.EXPIRED) {
        await RivertyService.resetExpiredPayment(payflow?.id as string);
        setError(
          t(
            'The attempt to pay with Riverty has expired or been cancelled. Please try again or choose another payment.',
          ),
        );
        setErrorRedirectURL(`/${payflow?.id}`);
      } else if (response.outcome == RivertyOutcome.REJECTED) {
        setError(
          t(
            'Unfortunately, your payment was rejected by Riverty due to not good enough credit score. Please try again with another payment method. Contact Riverty for any questions.',
          ),
        );
        setErrorRedirectURL(`/${payflow?.id}`);
      } else if (response.outcome == RivertyOutcome.CANCELLED) {
        // redirect to the primary page
        setLocation(`/${payflow?.id}`);
      } else if (response.outcome == RivertyOutcome.PENDING) {
        // continue to wait
        setTimeout(poll, 3000);
      } else {
        setError(t('Unknown payment status'));
        console.error('Unknown payment status');
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(apiErrorToText(error));
      } else {
        console.error(error);
      }
    }
  };

  React.useEffect(() => {
    poll();
  }, []);

  return (
    <BrandCard>
      <AlertDialog
        open={Boolean(error)}
        onClose={() => {
          setError(null);
          if (errorRedirectURL) {
            setLocation(errorRedirectURL);
          }
        }}
        heading={t('Error')}
        content={error}
        variant="error"
      />
      <div className="flex items-center justify-center">
        <img src={riverty_logo} alt='Riverty' />
      </div>
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    </BrandCard>
  );
};
