/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Waiting for the customer to give personal details to retrieve
 * the eFaktura identifier
 */
export enum EFakturaFunnelStatus {
  EFAKTURA_IDENTIFIER = 'EFAKTURA_IDENTIFIER',
  ADD_INVOICE = 'ADD_INVOICE',
  CONSIGNMENT_STATUS = 'CONSIGNMENT_STATUS',
  INVOICE_STATUS = 'INVOICE_STATUS',
  PROCESSED = 'PROCESSED',
  CONSIGNMENT_FAILED = 'CONSIGNMENT_FAILED',
  INVOICE_FAILED = 'INVOICE_FAILED',
}
