/* eslint @typescript-eslint/no-explicit-any: off, @typescript-eslint/no-unsafe-return: off, @typescript-eslint/no-unsafe-call: off */

import React, { FC } from 'react';
import { useParams, useLocation, Link } from 'wouter';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  PayflowGetResponse,
  PayflowSelectPaymentOptionResponse,
  OrganizationSupportInformation,
} from '../../payflow-client';
import { useRedirect } from '../../hooks/useRedirect';

import { Error } from '../Error';
import { Loader } from '../Loader';
import { BrandLogo } from '../BrandLogo';
import { HelpSection } from '../../sections/HelpSection';

// Organization type is not properly defined in payflow-client
type Organization = {
  legal_name: string | undefined;
  vat_number: string | undefined;
  address: {
    street_name: string | undefined;
    postal_code: string | undefined;
    city: string | undefined;
    country: string | undefined;
  };
};

export type PageData = {
  id: string;
  data: PayflowGetResponse | PayflowSelectPaymentOptionResponse | undefined;
  params: {
    payment_id: string | undefined;
    payment_option_id?: string | undefined;
    step_id?: string | undefined;
  };
  reactQueryResult?: UseQueryResult;
};

type PageProps = {
  children: (page: PageData) => JSX.Element | null | undefined;
  id: string;
  title?: string;
  queryFn: any;
  pageLoader?: boolean;
};

export const Page: FC<PageProps> = ({ children, id, title, queryFn, pageLoader = true }) => {
  const { t } = useTranslation();
  const { payment_id, payment_option_id, step_id } = useParams();
  const [location] = useLocation();
  const reactQueryResult = useQuery<
    PayflowGetResponse | PayflowSelectPaymentOptionResponse | undefined
  >({
    queryKey: [id, payment_id, payment_option_id],
    queryFn: async () =>
      payment_option_id
        ? await queryFn(payment_option_id, payment_id)
        : await queryFn(payment_id),
    enabled: Boolean(queryFn) && Boolean(payment_id),
  });

  useRedirect(reactQueryResult.data as PayflowGetResponse | PayflowSelectPaymentOptionResponse);

  document.title = title ? `${title} | Spense Pay` : 'Spense Pay';

  if (pageLoader && reactQueryResult.isFetching) return <Loader fullPage />;
  if (reactQueryResult.isError) return <Error error={reactQueryResult.error} />;
  if (!reactQueryResult.data && reactQueryResult.isFetched) return <Error error={t('No data')} />;

  let organization: Organization | null | undefined = undefined;
  let support_information: OrganizationSupportInformation | null | undefined =
    undefined;
  let logo_url: string | null | undefined = undefined;

  if (reactQueryResult.data && 'payflow' in reactQueryResult.data) {
    organization = reactQueryResult.data?.payflow?.organization as
      | Organization
      | null
      | undefined;
    support_information = reactQueryResult.data?.payflow?.support_information;
    logo_url = reactQueryResult.data?.payflow?.branding?.logo_url;
  } else if (reactQueryResult.data) {
    organization = reactQueryResult.data?.organization;
    support_information = reactQueryResult.data?.support_information;
    logo_url = reactQueryResult.data?.branding?.logo_url;
  }

  return (
    <div className="page relative">
      <BrandLogo logo_url={logo_url as string} />
      {children({
        id,
        data: reactQueryResult.data,
        params: {
          payment_id,
          ...(payment_option_id ? { payment_option_id } : {}),
          ...(step_id ? { step_id } : {}),
        },
        reactQueryResult,
      })}
      <HelpSection support_information={support_information} />
      <div className="px-3.5 text-base font-normal pb-2">
        {t('By using this service you accept our')}{' '}
        <Link
          to={`/${payment_id}/terms-and-conditions?backTo=${encodeURI(location)}`}
          className="underline"
        >
          {t('terms and conditions')}
        </Link>
      </div>
      <div className="px-3.5 pb-2 text-gray-400">
        <small>
          <div>{organization?.legal_name}</div>
          <div>{organization?.vat_number}</div>
          <div>{organization?.address?.street_name}</div>
          <div>{`${organization?.address?.postal_code} ${organization?.address?.city}`}</div>
          <div>{organization?.address?.country}</div>
        </small>
      </div>
    </div>
  );
};
