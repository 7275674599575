import { useParams, useSearch, useLocation, useRoute } from 'wouter';

import {
  PayflowGetResponse,
  PayflowSelectPaymentOptionResponse,
  PaymentStatus,
  RefundStatus,
  RefundStatusEnum,
} from '../payflow-client';

/**
 * Determines if the payment should be redirected to another location,
 * based on current route and payment status.
 *
 * @queryparam redirect - if value is set to false while in development env, no automatic redirections will be done
 * @param response PayflowGetResponse or PayflowSelectPaymentOptionResponse from API request
 * @returns null if no redirection is needed, or a new location string
 */
export const useRedirect = (
  response: PayflowGetResponse | PayflowSelectPaymentOptionResponse,
) => {
  const [location, setLocation] = useLocation();
  const params = useParams();
  const searchString = useSearch();
  const [matchOverviewRoute] = useRoute('/:payment_id');
  const [matchPaymentRoute] = useRoute('/:payment_id/:payment_option_id');
  const [matchReceiptRoute] = useRoute('/:payment_id/receipt');
  const [matchCancelledRoute] = useRoute('/:payment_id/cancelled');
  const [matchRefundedRoute] = useRoute('/:payment_id/refunded');
  const [matchPendingRoute] = useRoute('/:payment_id/pending');
  const [matchSplitPaymentRoute] = useRoute('/:payment_id/split-payment');
  const [matchReadMoreRoute] = useRoute('/:payment_id/read-more*');
  const [matchAdditionalStepRoute] = useRoute('/:payment_id/step/:step_id')
  const [matchToCRoute] = useRoute('/:payment_id/terms-and-conditions*');

  let paymentStatus;
  let refund;
  let redirectTo: string | null = null;
  let isSplitPaymentEnabled = false;
  let isRecurringAndHasCardOnFile = false;

  let payflow = null;

  if (response) {
    if ('payflow' in response) {
      payflow = response.payflow;
    } else {
      payflow = response;
    }

    paymentStatus = payflow?.status;
    refund = payflow?.refund as RefundStatus;
    isSplitPaymentEnabled = payflow?.split_payments_enabled;
    isRecurringAndHasCardOnFile = !!payflow?.payment?.recurring?.enabled && payflow?.split_payment?.split?.findIndex((split) => split.status == PaymentStatus.PAID) !== -1;
  }
  else {
    // We have no payflow
    return;
  }

  if (
    window.location?.hostname === 'payflow.localhost' &&
    searchString?.includes('redirect=false')
  ) {
    // eslint-disable-next-line no-console -- This is only logged in development
    console.log('Redirecting is disabled');
    return;
  }

  if (matchReadMoreRoute || matchToCRoute) {
    return;
  }

  if (!matchAdditionalStepRoute) {
    const customer_input = payflow?.profile?.customer_input.filter(step => step.enabled);
    if (customer_input.length > 0) {
      if (matchOverviewRoute) {
        const step = customer_input.find((step) => step.placement == "before_payment_options");
        if (step && !step.completed) {
          redirectTo = `/${params?.payment_id}/step/${step.id}?redirectTo=${location}`;
        }
      }
    }

    if (redirectTo && redirectTo !== location) {
      setLocation(redirectTo);
      return;
    }
  }

  if (isSplitPaymentEnabled && matchSplitPaymentRoute) {
    // Manually handle redirects from split payment page
    return;
  }

  switch (paymentStatus) {
    case PaymentStatus.SENT:
    case PaymentStatus.OPENED:
    case PaymentStatus.FAILED: {
      if (matchReceiptRoute || matchCancelledRoute || matchRefundedRoute || matchPendingRoute) {
        redirectTo = `/${params?.payment_id}`;
      }
      if (!redirectTo && !isSplitPaymentEnabled && matchSplitPaymentRoute) {
        redirectTo = `/${params?.payment_id}`;
      }
      if (isRecurringAndHasCardOnFile) {
        redirectTo = `/${params?.payment_id}/receipt`;
      }
      break;
    }
    case PaymentStatus.CANCELLED: {
      if (
        matchOverviewRoute ||
        matchPaymentRoute ||
        matchReceiptRoute ||
        matchRefundedRoute ||
        matchPendingRoute
      ) {
        redirectTo = `/${params?.payment_id}/cancelled`;
      }
      break;
    }
    case PaymentStatus.PENDING: {
      if (
        matchOverviewRoute ||
        matchPaymentRoute ||
        matchReceiptRoute ||
        matchRefundedRoute ||
        matchCancelledRoute
      ) {
        redirectTo = `/${params?.payment_id}/pending`;
      }
      break;
    }
    case PaymentStatus.PAID: {
      if (refund?.status === RefundStatusEnum.NOT_REFUNDED) {
        if (
          matchOverviewRoute ||
          matchPaymentRoute ||
          matchCancelledRoute ||
          matchRefundedRoute ||
          matchPendingRoute
        ) {
          redirectTo = `/${params?.payment_id}/receipt`;
        }
        break;
      } else {
        if (
          matchOverviewRoute ||
          matchPaymentRoute ||
          matchReceiptRoute ||
          matchCancelledRoute ||
          matchPendingRoute
        ) {
          redirectTo = `/${params?.payment_id}/refunded`;
        }
        break;
      }
    }
  }

  if (redirectTo && redirectTo !== location) {
    setLocation(redirectTo);
  }
};
