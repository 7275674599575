import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@heroicons/react/24/outline';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

import {
  PayflowGetResponse,
  PaymentStatus,
  SplitPaymentsService,
} from '../../payflow-client';
import { AlertDialog } from '../../components/AlertDialog';
import { BrandButton } from '../../components/inputs/BrandButton';
import { BrandCard } from '../../components/BrandCard';
import { Pill } from '../../components/Pill';
import { CallSplitIcon } from '../../components/icons/CallSplit';
import { useLocation } from 'wouter';
import { currencyFormat } from '../../utils/locale-utils';

type PaymentDetailsProps = {
  data: PayflowGetResponse;
  refetchData?:
    | ((
        options?: RefetchOptions | undefined,
      ) => Promise<QueryObserverResult<unknown, Error>>)
    | undefined;
};

export const SplitPaymentStatus: FC<PaymentDetailsProps> = ({
  data,
  refetchData,
}) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [showConfirmCancelPaymentDialog, setShowConfirmCancelPaymentDialog] =
    useState<number | null>(null);

  if (!data?.split_payment?.enabled) {
    return null;
  }

  const remainder_to_be_created =
    (data?.payment?.gross_amount || 0) -
    (data?.split_payment?.split
      ?.filter(split => split.status !== PaymentStatus.CANCELLED)
      .reduce((acc, split) => acc + split.total, 0) || 0);

  const mapStatusToPillVariant = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return 'success' as const;
      case PaymentStatus.SENT:
      case PaymentStatus.OPENED:
      case PaymentStatus.PENDING:
        return 'info' as const;
      case PaymentStatus.FAILED:
        return 'error' as const;
      case PaymentStatus.CANCELLED:
      default:
        return 'dark' as const;
    }
  };

  const mapStatusToPillText = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return t('Paid');
      case PaymentStatus.SENT:
        return t('Sent');
      case PaymentStatus.OPENED:
        return t('Opened');
      case PaymentStatus.PENDING:
        return t('Pending');
      case PaymentStatus.FAILED:
        return t('Failed');
      case PaymentStatus.CANCELLED:
        return t('Cancelled');
      default:
        return null;
    }
  };

  const handleCancelPayment = async () => {
    try {
      if (data?.split_payment?.split) {
        await SplitPaymentsService.cancelSplitPayment(
          data?.id as string,
          data?.split_payment?.split[showConfirmCancelPaymentDialog as number]
            ?.payflow_id as string,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      await refetchData?.();
    }
  };

  const handlePayRemainderNow = async () => {
    const response = await SplitPaymentsService.createRemainderPayment(
      data?.id as string,
    );
    if (response?.payflow_id) {
      setLocation(`/${response?.payflow_id}`);
    }
  };

  return (
    <>
      <BrandCard
        heading={
          <div className="flex flex-row justify-center items-center">
            <CallSplitIcon />
            <span className="ml-2">{t('Part payments')}</span>
          </div>
        }
      >
        <ul className="flex flex-col divide-y space-y-4 text-center">
          {data?.split_payment?.split?.map((split, index) => {
            return (
              <li key={index}>
                <div className={index > 0 ? 'mt-4' : ''}>
                  {split?.customer_id == data?.payment.customer_id ? (
                    <em>{t('You')}</em>
                  ) : (
                    split?.customer_name
                  )}
                </div>
                <div className="flex flex-row justify-center items-center space-x-2">
                  <Pill variant={mapStatusToPillVariant(split?.status)}>
                    {mapStatusToPillText(split?.status)}
                  </Pill>
                  <span
                    className={
                      split?.status === PaymentStatus.CANCELLED
                        ? 'line-through'
                        : ''
                    }
                  >
                    {currencyFormat(split?.total, data?.merchant_country)}
                  </span>
                </div>
                {split?.status === PaymentStatus.OPENED ||
                split?.status === PaymentStatus.SENT ? (
                  <BrandButton
                    onClick={() => setShowConfirmCancelPaymentDialog(index)}
                    variant="secondary"
                    className="w-full mt-4 !text-red-600"
                  >
                    <div className="flex flex-row justify-center items-center space-x-1">
                      <TrashIcon className="size-5" />
                      <span>{t('Cancel this payment')}</span>
                    </div>
                  </BrandButton>
                ) : null}
              </li>
            );
          })}
        </ul>
        {remainder_to_be_created > 0 ? (
          <>
            <div className="flex flex-row justify-center items-center mt-4">
              <BrandButton
                onClick={handlePayRemainderNow}
                variant="primary"
                className="w-full"
              >
                {t('Pay the remaining {{sum}} now', {
                  sum: currencyFormat(
                    remainder_to_be_created,
                    data?.merchant_country,
                  ),
                })}
              </BrandButton>
            </div>
            <div className="flex flex-row justify-center items-center mt-4">
              <BrandButton
                onClick={() => {
                  setLocation(`/${data?.id}/split-payment`);
                }}
                variant="secondary"
                className="w-full"
              >
                {t('Split the remaining {{sum}} with someone', {
                  sum: currencyFormat(
                    remainder_to_be_created,
                    data?.merchant_country,
                  ),
                })}
              </BrandButton>
            </div>
          </>
        ) : null}
      </BrandCard>
      <AlertDialog
        open={showConfirmCancelPaymentDialog !== null}
        heading={t('Are you sure?')}
        variant="error"
        onClose={() => {
          setShowConfirmCancelPaymentDialog(null);
        }}
        onConfirmClick={handleCancelPayment}
        confirmButtonText={t('Yes, cancel this payment')}
        cancelButtonText={t('No, keep this payment')}
      />
    </>
  );
};
