/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeyboxIntegrationDB } from '../models/KeyboxIntegrationDB';
import type { PayflowGetResponse } from '../models/PayflowGetResponse';
import type { PayflowOpenKeyboxRequest } from '../models/PayflowOpenKeyboxRequest';
import type { PayflowSelectPaymentOptionResponse } from '../models/PayflowSelectPaymentOptionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaymentService {
  /**
   * Ping
   * @param payflowId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static ping(
    payflowId: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/{payflow_id}/ping',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get
   * Retrieve a payflow.
   * @param payflowId
   * @returns PayflowGetResponse Successful Response
   * @throws ApiError
   */
  public static get(
    payflowId: string,
  ): CancelablePromise<PayflowGetResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{payflow_id}',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Select Payment Option
   * Select a payment option for a payflow.
   *
   * This call can be called many times for the same payflow. The first time it is
   * called, it will use the selected payment provider to create a payment with their
   * API.
   * @param paymentOptionId
   * @param payflowId
   * @returns PayflowSelectPaymentOptionResponse Successful Response
   * @throws ApiError
   */
  public static selectPaymentOption(
    paymentOptionId: string,
    payflowId: string,
  ): CancelablePromise<PayflowSelectPaymentOptionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/{payflow_id}/select-payment-option/{payment_option_id}',
      path: {
        'payment_option_id': paymentOptionId,
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Receipt
   * Receipt a payflow.
   * @param payflowId
   * @returns PayflowGetResponse Successful Response
   * @throws ApiError
   */
  public static receipt(
    payflowId: string,
  ): CancelablePromise<PayflowGetResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/{payflow_id}/receipt',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Open Keybox
   * Open a keybox.
   * @param payflowId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static openKeybox(
    payflowId: string,
    requestBody: PayflowOpenKeyboxRequest,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/{payflow_id}/open_keybox',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Save Customer Input
   * Save customer input.
   * @param payflowId
   * @param customerInputId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static saveCustomerInput(
    payflowId: string,
    customerInputId: string,
    requestBody: Record<string, any>,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/{payflow_id}/customer_input',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'customer_input_id': customerInputId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Keybox
   * Retrieve a keybox.
   * @param keyboxId
   * @param payflowId
   * @returns KeyboxIntegrationDB Successful Response
   * @throws ApiError
   */
  public static getKeybox(
    keyboxId: string,
    payflowId: string,
  ): CancelablePromise<KeyboxIntegrationDB> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{payflow_id}/keybox/{keybox_id}',
      path: {
        'keybox_id': keyboxId,
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Download Receipt
   * Download the receipt for a payflow.
   * @param payflowId
   * @param download
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadReceipt(
    payflowId: string,
    download: boolean = true,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{payflow_id}/download-receipt',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'download': download,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
