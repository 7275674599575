import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { GoogleMaps } from '../components/GoogleMaps';
import { PaymentDetails } from '../sections/PaymentDetails';
import { Keybox } from '../sections/Keybox';

export const ReceiptPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  return (
    <Page
      id="receipt"
      title={t('Receipt')}
      queryFn={PaymentService ? PaymentService.receipt : null}
    >
      {page =>
        page?.data && 'payment' in page.data ? (
          <>
            <GoogleMaps {...page.data?.branding?.map} />
            <PaymentDetails data={page?.data} />
            <Keybox data={page?.data} />
          </>
        ) : null
      }
    </Page>
  );
};
