import React, { FC, ReactNode } from 'react';

type BrandButtonType = {
  children: ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
};

export const BrandButton: FC<BrandButtonType> = ({
  children,
  onClick,
  variant = 'secondary',
  disabled,
  className,
}) => {
  return (
    <button
      className={
        `flex flex-nowrap truncate h-12 p-3 btn-${variant}${disabled ? ' opacity-50' : ''}` +
        (className ? ` ${className}` : '')
      }
      onClick={onClick}
      disabled={disabled}
    >
      <span className="grow h-full mx-2 truncate">{children}</span>
    </button>
  );
};
