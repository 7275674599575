import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PayflowGetResponseProfile,
  PayflowProfileCustomerInputPlacement,
  PayflowType,
} from '../payflow-client';
import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { PaymentDetails } from '../sections/PaymentDetails';
import { PaymentOptionsList } from '../sections/PaymentOptionsList';
import { CallSplitIcon } from '../components/icons/CallSplit';
import { SplitPaymentStatus } from '../sections/SplitPaymentStatus';
import { getNextStepRedirectUrl } from '../utils/getNextStepRedirectUrl';

export const OverviewPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();

  return (
    <Page
      id="overview"
      title={t('Overview')}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page =>
        page?.data && 'payment_options' in page.data ? (
          <>
            <PaymentDetails data={page?.data} />
            {!page?.data?.split_payment?.enabled ? (
              <PaymentOptionsList
                // eslint-disable-next-line
                profile={page?.data?.profile}
                payment_options={page?.data?.payment_options}
                payment_id={page?.params?.payment_id}
              />
            ) : null}
            {page?.data?.split_payments_enabled &&
            !page?.data?.split_payment?.enabled &&
            page?.data?.payflow_type == PayflowType.PAYMENT ? (
              <ul className="flex flex-col space-y-4">
                <li>
                  <a
                    href={getNextStepRedirectUrl(
                      `/${page?.params?.payment_id}/split-payment`,
                      page?.params?.payment_id as string,
                      page?.data?.profile,
                      PayflowProfileCustomerInputPlacement.AFTER_PAYMENT_OPTIONS,
                    )}
                    className="flex flex-nowrap truncate h-12 p-3 btn-secondary font-medium ring-1 ring-inset hover:ring-0"
                  >
                    <span className="grow-0 h-full">
                      <span className="flex h-full space-x-2">
                        <CallSplitIcon />
                      </span>
                    </span>
                    <span className="grow h-full mx-2 truncate">
                      {t('Split the bill with someone')}
                    </span>
                  </a>
                </li>
              </ul>
            ) : null}
            {page?.data?.split_payment?.enabled ? (
              <SplitPaymentStatus
                data={page?.data}
                refetchData={page?.reactQueryResult?.refetch}
              />
            ) : null}
          </>
        ) : null
      }
    </Page>
  );
};
