import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  PayflowGetResponse,
  PaymentStatus,
  RefundStatusEnum,
  PaymentService,
} from '../../payflow-client';

import { Sharebox } from '../../keybox-integrations/Sharebox';
import { ManualPinCode } from '../../keybox-integrations/ManualPinCode';
import { LivionKey } from '../../keybox-integrations/LivionKey';

type KeyboxProps = {
  data: PayflowGetResponse;
};

export const Keybox: FC<KeyboxProps> = ({ data }) => {
  const isRefunded = data?.refund?.status !== RefundStatusEnum.NOT_REFUNDED;
  const isPaid = !isRefunded && data?.status === PaymentStatus.PAID;
  const isKeybox = !!data?.payment?.metadata?.keybox?.keybox_id;

  const { data: keybox } = useQuery({
    queryKey: [
      'keybox',
      data?.status,
      data?.refund?.status,
      data?.payment?.metadata?.keybox?.keybox_id,
    ],
    queryFn: async () =>
      await PaymentService.getKeybox(
        data?.payment?.metadata?.keybox?.keybox_id as string,
        data?.id as string,
      ),
    enabled: isKeybox && isPaid,
  });

  if (!keybox?.id) {
    return null;
  }

  switch (keybox?.integration_identifier) {
    case 'sharebox':
      return <Sharebox data={data} keybox={keybox} />;
    case 'livionkey':
      return <LivionKey data={data} keybox={keybox} />;
    case 'manualpincode':
      return <ManualPinCode data={data} keybox={keybox} />;
    default:
      console.error(
        `The keybox '${keybox?.integration_name}' is not yet supported by Spense.`,
      );
      return null;
  }
};
