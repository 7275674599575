/* eslint @typescript-eslint/no-floating-promises: off */

import React, { FC, useContext, useEffect } from 'react';
import { Route, Switch, useRoute } from 'wouter';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { keepPreviousData } from '@tanstack/react-query';

import { queryClient } from './main';
import { Error } from './components/Error';
import { BaseLayout } from './layouts/BaseLayout';
import { NotFoundPage } from './pages/NotFound';
import { TermsAndConditionsPage } from './pages/TermsAndConditions';
import { ReadMorePage } from './pages/ReadMore';
import { OverviewPage } from './pages/Overview';
import { PaymentPage } from './pages/Payment';
import { ReceiptPage } from './pages/Receipt';
import { CancelledPage } from './pages/Cancelled';
import { RefundedPage } from './pages/Refunded';
import { PendingPage } from './pages/Pending';
import { SplitPaymentPage } from './pages/SplitPayment';
import { AdditionalPaymentStepPage } from './pages/AdditionalPaymentStep';
import initializePing from './utils/ping';
import { PayflowContext } from './contexts/payflow-context';
import { applyLegacyTheme } from './utils/theme/legacyTheme';
import { mapLocale } from './utils/locale-utils';
import { PayflowProfileBranding, OpenAPI } from './payflow-client';

import './utils/i18n';

const App: FC = () => {
  const { i18n } = useTranslation();
  const [, params] = useRoute(':payment_id/*?');
  const { PaymentService } = useContext(PayflowContext);

  queryClient.setDefaultOptions({
    queries: {
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      retry() {
        return false;
      },
    },
  });

  const { data, isFetched } = useQuery({
    queryKey: ['branding', params?.payment_id],
    queryFn: async () =>
      await PaymentService?.get(params?.payment_id as string),
    enabled: Boolean(PaymentService) && Boolean(params?.payment_id),
  });

  useEffect(() => {
    if (isFetched) {
      const locale = mapLocale(data?.merchant_country || navigator.language);
      i18n.changeLanguage(locale);
      OpenAPI.HEADERS = {
        'Accept-Language': locale.toUpperCase(),
      };
      applyLegacyTheme(data?.branding as PayflowProfileBranding);
    }
  }, [i18n, isFetched, data?.branding, data?.merchant_country]);

  if (params?.payment_id) {
    initializePing(params?.payment_id);
  }

  return (
    <BaseLayout>
      <ErrorBoundary FallbackComponent={Error}>
        <Switch>
          <Route path="/404" component={NotFoundPage} />
          <Route path="/:payment_id" component={OverviewPage} />
          <Route
            path="/:payment_id/terms-and-conditions"
            component={TermsAndConditionsPage}
          />
          <Route path="/:payment_id/read-more" component={ReadMorePage} />
          <Route path="/:payment_id/receipt" component={ReceiptPage} />
          <Route path="/:payment_id/cancelled" component={CancelledPage} />
          <Route path="/:payment_id/refunded" component={RefundedPage} />
          <Route path="/:payment_id/pending" component={PendingPage} />
          <Route
            path="/:payment_id/split-payment"
            component={SplitPaymentPage}
          />
          <Route path="/:payment_id/step/:step_id" component={AdditionalPaymentStepPage} />
          <Route
            path="/:payment_id/:payment_option_id"
            component={PaymentPage}
          />
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </ErrorBoundary>
    </BaseLayout>
  );
};

export default App;
